import React from 'react';
import "./footer.css";


const Footer = () => {
    return (
        <div className='container-fluid m-0 p-0 footer'>
            <div className='row'>
                <div className='col-md-12'>
                    <h5 className=' trendingSearches'>Trending Searches</h5>
                    <div className='serchesKeyword '>
                        <a href="https://www.loriat.com/bras/s/">Ladies Bra</a>|
                        <a href="https://www.loriat.com/bras/sports-bra/s/">Sports Bra</a>|
                        <a href="https://www.loriat.com/bras/bra-panty-sets/s/">Bra Panty</a>|
                        <a href="https://www.loriat.com/bras/beginners_teenagers/s/">Bra For Girls</a>|
                        <a href="https://www.loriat.com/bras/sexy-bras/s/">Sexy Bra</a>|
                        <a href="https://www.loriat.com/bras/nursing-feeding-bras/s/">Feeding Bra</a>|
                        <a href="https://www.loriat.com/bras/bridal-bras/s/">Bridal Bra</a>|
                        <a href="https://www.loriat.com/bras/padded-bras/s/">Padded Bra</a>|
                        <a href="https://www.loriat.com/bras/push-up-bra/s/">Push Up Bra</a>|
                        <a href="https://www.loriat.com/bras/strapless-multiway-bras/s/">Strapless Bra</a>|
                        <a href="https://www.loriat.com/bras/bralette/s/">Bralette</a>|
                        <a href="https://www.loriat.com/bras/backless-bra/s/">Backless Bra</a>|
                        <a href="https://www.loriat.com/bras/cotton-bra/s/">Cotton Bra</a>|
                        <a href="https://www.loriat.com/bras/full-coverage-bras/s/">Full Coverage Bra</a>|
                        <a href="https://www.loriat.com/bras/underwire-bra/s/">Underwire Bra</a>|
                        <a href="https://www.loriat.com/bras/t-shirt-bras/s/">T-Shirt Bra</a>|
                        <a href="https://www.loriat.com/bra-size-calculator/">Bra Size Calculator</a>|
                        <a href="https://www.loriat.com/bras/transparent-bras/s/">Transparent Bra</a>|
                        <a href="https://www.loriat.com/bras/tube-bra/s/">Tube Bra</a>|
                        <a href="https://www.loriat.com/bras/designer-bras/s/">Bra Design</a>|
                        <a href="https://www.loriat.com/bras/lace-bras/s/">Lace Bra</a>|
                        <a href="https://www.loriat.com/bras/fancy-bra/s/">Fancy Bra</a>|
                        <a href="https://www.loriat.com/bras/balconette-bras/s/">Balconette Bra</a>|
                        <a href="https://www.loriat.com/bras/stick-on-bras/s/">Stick On Bras</a>|
                        <a href="https://www.loriat.com/bras/minimizer-bra/s/">Minimizer Bra</a>|
                        <a href="https://www.loriat.com/bras/plunge-bra/s/">Plunge Bra</a>|
                        <a href="https://www.loriat.com/bras/net-bra/s/">Net Bra</a>|
                        <a href="https://www.loriat.com/bras/large-size-bra/s/">Plus Size Bra</a>|
                        <a href="https://www.loriat.com/bras/seamless-bra/s/">Seamless Bra</a>|
                        <a href="https://www.loriat.com/bras/demi-cup-bras/s/">Demi Cup Bras</a>|
                        <a href="https://www.loriat.com/bras/halter-neck-bra/s/">Halter Neck Bra</a>|
                        <a href="https://www.loriat.com/bras/cage-bra/s/">Cage Bra</a>|
                        <a href="https://www.loriat.com/blog/21-types-of-bras-you-should-know-about/">Types of Bras</a>|
                        <a href="https://www.loriat.com/blog/bra-size-chart/">Bra Size Chart</a>|
                        <a href="https://www.loriat.com/bras/30-bras-size/">30 Bra Size</a>|
                        <a href="https://www.loriat.com/bras/32-bras-size/">32 Bra Size</a>|
                        <a href="https://www.loriat.com/bras/34-bras-size/">34 Bra Size</a>|
                        <a href="https://www.loriat.com/bras/36-bras-size/">36 Bra Size</a>|
                        <a href="https://www.loriat.com/bras/black-bra/s/">Black Bra</a>|
                        <a href="https://www.loriat.com/bras/red-bra/s/">Red Bra</a>|
                        <a href="https://www.loriat.com/bras/nude-bra/s/">Nude Bra</a>|
                        <a href="https://www.loriat.com/bras/pink-bra/s/">Pink Bra</a>|
                        <a href="https://www.loriat.com/bras/white-bra/s/">White Bra</a>|
                        <a href="https://www.loriat.com/panties/s/">Panties</a>|
                    </div>






                </div>

                <div className='col-md-12'>
                    <div className='row'>
                        <div className='col footerLinks '>
                            <ul classname = "">
                                <li><p className="footerHeading">BRAS</p></li>
                                <li><a href="/bras/backless-bra/s/"> Backless Bra </a></li>
                                <li><a href="/bras/bralette/s/"> Bralette</a></li>
                                <li><a href="/bridal-lingerie/bridal-bras/s/">Bridal Bra</a></li>
                                <li><a href="/bras/cotton-bra/s/">Cotton Bra</a></li>
                                <li><a href="/bras/full-coverage-bras/s/"> Full Coverage Bra </a></li>
                                <li><a href="/bras/padded-bras/s/">Padded Bra</a></li>
                                <li><a href="/bras/push-up-bra/s/"> Push Up Bra </a></li>
                                <li><a href="/bras/sexy-bras/s/">Sexy Bra</a></li>
                                <li><a href="/bras/strapless-multiway-bras/s/">Strapless Bra </a></li>
                                <li><a href="/bras/t-shirt-bras/s/"> T-shirt Bra </a></li>
                                <li><a href="/bras/underwire-bra/s/">Underwire</a></li>
                            </ul>
                        </div>
                        <div className='col footerLinks'>
                            <ul classname = "">
                                <li><p className="footerHeading">PANTIES</p></li>
                                <li><a href="/panties/bikini-briefs/s/"> Bikini Panties </a></li>
                                <li><a href="/panties/boy-shorts-briefs/s/"> Boy Shorts </a></li>
                                <li><a href="/bras/bra-panty-sets/s/"> Bra Panty Set </a></li>
                                <li><a href="/panties/cotton-panties/s/"> Cotton Panties </a></li>
                                <li><a href="/panties/hipster-panties/s/"> Hipster </a></li>
                                <li><a href="/panties/sexy-panties/s/">Sexy Panties </a></li>
                                <li><a href="/panties/thongs/s/">Thong </a></li>
                            </ul>
                        </div>
                        <div className='col footerLinks'>
                            <ul classname = "">
                                <li><p className="footerHeading">NIGHTWEAR</p></li>
                                <li><a href="/nightwear/babydoll-chemise/s/"> Babydoll </a></li>
                                <li><a href="/nightwear/bridal-nightwear/s/"> Bridal Nightwear </a></li>
                                <li><a href="/nightwear/camisoles/s/"> Camisole</a></li>
                                <li><a href="/nightwear/cotton-night-dress/s/"> Cotton Nightwear </a></li>
                                <li><a href="/nightwear/night-suits/s/"> Night Suit </a></li>
                                <li><a href="/nightwear/nighty-and-night-dress/s/"> Nighty/Night Dress</a></li>
                                <li><a href="/nightwear/pajamas-1/s/"> Pajamas</a></li>
                                <li><a href="/nightwear/sexy-nightwear/s/">Sexy Nightwear </a></li>
                                <li><a href="/nightwear/tank-top/s/"> Tank Top </a></li>
                            </ul>
                        </div>
                        <div className='col footerLinks'>
                            <ul classname = "">
                                <li><p class="footerHeading">ACTIVEWEAR</p></li>
                                <li><a href="/active-wear/gym-shorts/s/">Active Shorts</a></li>
                                <li><a href="/bras/sports-bra/s/">Sports Bra</a></li>
                                <li><a href="/active-wear/sports-wear-t-shirts/s/">Sports T shirts</a></li>
                                <li><a href="/active-wear/tights-1/s/">Tights</a></li>
                                <li><a href="/active-wear/gym-wear/s/">Gym Wear</a></li>
                                <li><a href="/active-wear/yoga-clothing/s/">Yoga Dress</a></li>
                            </ul>
                        </div>
                        <div className='col '>
                            <div className='row'>
                                <div className='col-md-6 footerLinks'>
                                    <li><p class="footerHeading">QUICK LINKS</p></li>
                                    <li><a target="_blank" href="/blog/">Magazine</a></li>
                                    <li><a href="/clovia-curve/">CloviaCurve<sup>TM</sup>&nbsp;Fit Test</a></li>
                                    <li><a href="/bra-size-calculator/">Bra Size Calculator</a></li>
                                    <li><a href="/find-your-bra/"> Bra For Your Dress </a></li>
                                    <li><a href="/shop-by-sizes/"> Shop By Sizes </a></li>
                                    <li><a href="/shop-by-colors/"> Shop By Colors</a></li>
                                    <li><a href="/period-tracker/"> Period Tracker </a></li>
                                    <li><a href="/wishlist/"> Save For Later </a></li>
                                    <li><a href="/run-your-own-business/"> Clovia Partnership Program</a></li>
                                    <li><a href="/reviews/">Clovia Reviews</a></li>
                                    <li><a href="/salesandservice/">Sales and Service</a></li>
                                    <li><a href="/own-a-franchisee/">Own A Franchisee</a></li>
                                    <li><a href="/store-locator/">Clovia Store Locator</a></li>
                                    <li><a href="/responsible-disclosure/">Clovia Responsible Disclosure Policy</a></li>
                                </div>
                                <div className='col-md-6 footerLinks'>
                                    <li><a href="/blog/about-clovia/"> About Us</a></li>
                                    <li><a href="/contact-us-now/"> Contact Us </a></li>
                                    <li><a href="/shippingpolicy/"> Shipping Policy </a></li>
                                    <li><a href="/privacy-policy/"> Privacy Policy </a></li>
                                    <li><a href="/termsconditions/"> Terms &amp; Conditions </a></li>
                                    <li><a href="/contact-us-now/"> Trade Enquiries </a></li>
                                    <li><a href="/return-policy/"> Return &amp; Exchange Policy </a></li>
                                    <a href="/track-order/">Track your order</a>
                                    <li><a href="/discreet-packaging/">Discreet Packaging</a></li>
                                    <li><a href="/gentlemen-guide/"> Gentlemen's Guide </a></li>
                                    <li><a href="/refer-&amp;-earn/"> Refer &amp; Earn </a></li>
                                    <li><a href="/view-cart/"> My Bag </a></li>
                                    <li><a href="/sitemap.html"> Sitemap </a></li>
                                    <li><a href="/faqs/"> FAQs </a></li>
                                    <li><a href="/clovia-coupons/"> Clovia Coupons </a></li>
                                    <li><a href="https://careers.clovia.com">Careers</a></li>
                                </div>
                            </div>

                        </div>



                        <div className='col footerLinks'>
                        <li><p class="footerHeading">STAY IN TOUCH</p></li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;