import "../Components/Home.css";
import Footer from "./layout/footer";
import ProductsCards from "./productPage/productsCards";

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import 'swiper/css';

// import required modules
import { Autoplay, FreeMode, Pagination } from 'swiper/modules';

import Home1 from "../images/Home1.png";
import HomeOfferAd from "../images/HomeOfferAd.png";
import HomeOfferAd2 from "../images/HomeOfferAd2.jpg";
import HomeCategory1 from "../images/HomeCategory1.png";
import HomeCategory2 from "../images/HomeCategory2.png";
import HomeOfferAd3 from "../images/HomeOfferAd3.jpg";
import HomeShopNow1 from "../images/HomeShopNow1.png";
import HomeCategory3 from "../images/HomeCategory3.png";
import HomeCategory3ad from "../images/HomeCategory3ad.jpg";
import topShorts1 from "../images/topShorts1.jpg";
import topShorts2 from "../images/topShorts2.jpg";
import squatimg from "../images/squatimg.png";
import forYourCurves from "../images/forYourCurves.png";
import lovinShapes from "../images/lovinShapes.png";
import teenCollection from "../images/teenCollection.png";
import madeForNewMoms from "../images/madeForNewMoms.png";
import maternity from "../images/maternity.png";
import feeding from "../images/feeding.png";
import secondCorouselimg from "../images/secondCorouselimg.avif";
import intimateCareEssentials from "../images/intimateCareEssentials.avif";
import pads from "../images/pads.avif";
import intimateWash from "../images/intimateWash.avif";
import liners from "../images/liners.avif";
import tuneinWithClovia from "../images/tuneinWithClovia.avif";
import tuneinWithCloviaCon from "../images/tuneinWithCloviaCon.avif";
import tuneinWithCloviaCon1 from "../images/tuneinWithCloviaCon1.avif";
import tuneinWithCloviaCon2 from "../images/tuneinWithCloviaCon2.avif";
import video1 from "../videos/REASONS_TO_STAY_ACTIVE.mp4";
import video2 from "../videos/Shraddha_Websie.mp4";
import video3 from "../videos/CHINKI_MINKI_600X400.mp4";
import celebSpottedinClov from "../images/celebSpottedinClov.avif";
import awardsReco from "../images/awardsReco.avif";
import secretSauce from "../images/secretSauce.avif";

import loriatfitTest from "../images/loriatfitTest.avif";
import referafriend from "../images/referafriend.avif";
import gentlemanguide from "../images/gentlemanguide.avif";

import fertiloitytrack from "../images/fertiloitytrack.avif";
import braforyourdress from "../images/braforyourdress.avif";
import periodtracker from "../images/periodtracker.avif";
import dthptracker from "../images/dthptracker.avif";


import loriatapp from "../images/loriatapp.avif";
import storelocator from "../images/storelocator.avif";
import iosapp from "../images/iosapp.avif";

import womensfitness from "../images/womens-fitness.avif";
import sustainablefashion from "../images/sustainable-fashion.avif";
import ling from "../images/ling.jpeg";





const Home = () => {
  return (
    <>
      <div className="main-home mt-4 pt-5">
        <div className="Home mainHomeCont container-fluid m-0 p-0">
          <div
            id="carouselExampleInterval"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active" data-bs-interval="3000">
                <img
                  style={{ height: "725px" }}
                  className="img-fluid"
                  src={Home1}
                  alt="1"
                />
              </div>
              <div class="carousel-item" data-bs-interval="3000">
                <img
                  style={{ height: "725px" }}
                  className="img-fluid"
                  src={Home1}
                  alt="2"
                />
              </div>
              <div class="carousel-item" data-bs-interval="3000">
                <img
                  style={{ height: "725px" }}
                  className="img-fluid"
                  src={Home1}
                  alt="3"
                />
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleInterval"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleInterval"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>

      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 mt-3">
            <img className="img-fluid w-100" src={HomeOfferAd} />
          </div>
          <div className="col-md-12 ">
            <img className="img-fluid w-100" src={HomeOfferAd2} />
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <img className="img-fluid w-100" src={HomeCategory1} />
              </div>
              <div className="col-md-6">
                <img className="img-fluid w-100" src={HomeCategory2} />
              </div>
            </div>


          </div>


          <div className="col-md-12">
            <img className="img-fluid w-100" src={HomeOfferAd3} />
          </div>

          <div className="col-md-12">
            <img className="adjustIMG img-fluid" src={HomeShopNow1} />
          </div>
          <div className="col-md-12 mt-4">
            <img className=" img-fluid w-100" src={HomeCategory3} />
          </div>

          <div className="col-md-12 mt-4">
            <img className="img-fluid w-100" src={HomeCategory3ad} />
          </div>

          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <img className="img-fluid w-100" src={topShorts1} />
              </div>
              <div className="col-md-6">
                <img className="img-fluid w-100" src={topShorts2} />
              </div>
            </div>


          </div>

          <div className="col-md-12 mt-4">
            <img className="img-fluid w-100" src={squatimg} />
          </div>

          <div className="col-md-12 mt-4">
            <img className="img-fluid w-100" src={forYourCurves} />
          </div>

          <div className="col-md-12 mt-4">
            <img className="img-fluid w-100" src={lovinShapes} />
          </div>

          <div className="col-md-12 mt-4">
            <img className="img-fluid w-100" src={teenCollection} />
          </div>
          <div className="col-md-12 mt-4">
            <img className="img-fluid w-100" src={madeForNewMoms} />
          </div>
          <div className="col-md-12 mt-4">
            <div className="row">
              <div className="col-md-6">
                <img className="img-fluid w-100" src={maternity} />
              </div>
              <div className="col-md-6">
                <img className="img-fluid w-100" src={feeding} />
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div
              id="carouselsecond"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-inner">
                <div class="carousel-item active" data-bs-interval="3000">
                  <img
                    style={{ height: "725px" }}
                    className="img-fluid"
                    src={secondCorouselimg}
                    alt="1"
                  />
                </div>
                <div class="carousel-item" data-bs-interval="3000">
                  <img
                    style={{ height: "725px" }}
                    className="img-fluid"
                    src={secondCorouselimg}
                    alt="2"
                  />
                </div>
                <div class="carousel-item" data-bs-interval="3000">
                  <img
                    style={{ height: "725px" }}
                    className="img-fluid"
                    src={secondCorouselimg}
                    alt="3"
                  />
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselsecond"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselsecond"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>

          <div className="col-md-12 mt-4">
            <img className="img-fluid w-100" src={intimateCareEssentials} />
          </div>

          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4">
                <img className="img-fluid w-100" src={pads} />
              </div>
              <div className="col-md-4">
                <img className="img-fluid w-100" src={intimateWash} />
              </div>
              <div className="col-md-4">
                <img className="img-fluid w-100" src={liners} />
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <img className="img-fluid w-100" src={tuneinWithClovia} />
          </div>

          <div className="col-md-12 mt-4">
            <div className="row">
              <div className="col-md-4">
                <img className="img-fluid w-100" src={tuneinWithCloviaCon1} />
              </div>
              <div className="col-md-4">
                <img className="img-fluid w-100" src={tuneinWithCloviaCon} />
              </div>
              <div className="col-md-4">
                <img className="img-fluid w-100" src={tuneinWithCloviaCon2} />
              </div>
            </div>

          </div>

          <div className="col-md-12 mt-4">
            <div className="row g-3">
              <div className="col-md-4">
                <div className="video-container">
                  <video className="scaled-video" src={video1} autoPlay muted loop></video>
                </div>
              </div>
              <div className="col-md-4">
                <div className="video-container">
                  <video className="scaled-video" src={video2} autoPlay muted loop></video>
                </div>
              </div>
              <div className="col-md-4">
                <div className="video-container">
                  <video className="scaled-video" src={video3} autoPlay muted loop></video>
                </div>
              </div>
            </div>

          </div>



          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <img className="img-fluid w-100" src={awardsReco} />
              </div>
              <div className="col-md-6">
                <img className="img-fluid w-100" src={celebSpottedinClov} />
              </div>

            </div>
          </div>


          <div className="col-md-12 mt-4">
            <img className="img-fluid w-100" src={secretSauce} />
          </div>

          <div className="col-md-12 mt-4">
            <img className="img-fluid w-100" src={loriatfitTest} />
          </div>

          <div className="col-md-12 mt-4">
            <img className="img-fluid w-100" src={referafriend} />
          </div>

          <div className="col-md-12 mt-4">
            <img className="img-fluid w-100" src={gentlemanguide} />
          </div>

          <div className="col-md-12 mt-4">
            <div className="row">
              <div className="col-md-4">
                <img className="img-fluid w-100" src={fertiloitytrack} />
              </div>
              <div className="col-md-4">
                <img className="img-fluid w-100" src={braforyourdress} />
              </div>
              <div className="col-md-4">
                <img className="img-fluid w-100" src={periodtracker} />
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-4">
            <img className="img-fluid w-100" src={dthptracker} />
          </div>


          <div className="col-md-12 mt-4">
            <div className="row">
              <div className="col-md-4">
                <img className="img-fluid w-100" src={loriatapp} />
              </div>
              <div className="col-md-4">
                <img className="img-fluid w-100" src={storelocator} />
              </div>
              <div className="col-md-4">
                <img className="img-fluid w-100" src={iosapp} />
              </div>
              <div className="col-md-4">
                <img className="img-fluid w-100" src={womensfitness} />
              </div>
              <div className="col-md-4">
                <img className="img-fluid w-100" src={sustainablefashion} />
              </div>
              <div className="col-md-4">
                <img className="img-fluid w-100" src={ling} />
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-4">
            <Swiper
              slidesPerView={4}
              spaceBetween={30}
              freeMode={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, FreeMode, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="card-container">
                  <div className="card card_sizing">
                    <div className="card-body">
                      <h5 className="card-title"></h5>
                      <p className="card-text">All the products which we received are too good and the delivery is too fast. Expecting to shop more from the collection.</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>



              <SwiperSlide>
                <div className="card-container">
                  <div className="card card_sizing">
                    <div className="card-body">
                      <h5 className="card-title"></h5>
                      <p className="card-text">All the products which we received are too good and the delivery is too fast. Expecting to shop more from the collection.</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>


              <SwiperSlide>
                <div className="card-container">
                  <div className="card card_sizing">
                    <div className="card-body">
                      <h5 className="card-title"></h5>
                      <p className="card-text">All the products which we received are too good and the delivery is too fast. Expecting to shop more from the collection.</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>


              <SwiperSlide>
                <div className="card-container">
                  <div className="card card_sizing">
                    <div className="card-body">
                      <h5 className="card-title"></h5>
                      <p className="card-text">All the products which we received are too good and the delivery is too fast. Expecting to shop more from the collection.</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>


              <SwiperSlide>
                <div className="card-container">
                  <div className="card card_sizing">
                    <div className="card-body">
                      <h5 className="card-title"></h5>
                      <p className="card-text">All the products which we received are too good and the delivery is too fast. Expecting to shop more from the collection.</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card-container">
                  <div className="card card_sizing">
                    <div className="card-body">
                      <h5 className="card-title"></h5>
                      <p className="card-text">All the products which we received are too good and the delivery is too fast. Expecting to shop more from the collection.</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card-container">
                  <div className="card card_sizing">
                    <div className="card-body">
                      <h5 className="card-title"></h5>
                      <p className="card-text">All the products which we received are too good and the delivery is too fast. Expecting to shop more from the collection.</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card-container">
                  <div className="card card_sizing">
                    <div className="card-body">
                      <h5 className="card-title"></h5>
                      <p className="card-text">All the products which we received are too good and the delivery is too fast. Expecting to shop more from the collection.</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card-container">
                  <div className="card card_sizing">
                    <div className="card-body">
                      <h5 className="card-title"></h5>
                      <p className="card-text">All the products which we received are too good and the delivery is too fast. Expecting to shop more from the collection.</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>


         
          <ProductsCards/>
        <Footer/>
        </div>


      </div>
    </>
  );
};
export default Home;
