import React, { useState, useEffect } from 'react';
import './navbar.css';
import { Link } from 'react-router-dom';
import Submenu from '../../data/dataSubcat.json';
import Menu from '../../data/dataMenu.json';
import Tag from '../../data/tag.json';
import getTagData from '../../data/data.json'
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import CartTooltip from '../../pages/CartTooltip';
import Sidebar from "../sidebar";

import logo from "../../images/loriat_logo.png";

import { Popover, Whisper, Button, Dropdown } from 'rsuite';

const MenuPopover = React.forwardRef(({ onSelect, ...rest }, ref) => (
    <Popover ref={ref} {...rest} full>
        <div className='container'>
            <div className='row d-block'>
                <div className='col-md-12'>
                    <div className='row'>
                        <div className='col'> <button>Login</button></div>
                        <div className='col'> <button>Signup</button></div>
                    </div>

                </div>
                <div className='col-md-4'>

                    <Dropdown.Menu onSelect={onSelect}>
                        <Dropdown.Item eventKey={1}>My Profile</Dropdown.Item>
                        <Dropdown.Item eventKey={2}>Orders</Dropdown.Item>
                        <Dropdown.Item eventKey={3}>Wishlist</Dropdown.Item>
                        <Dropdown.Item eventKey={4}>Rewards</Dropdown.Item>
                        <Dropdown.Item eventKey={5}>Gift Cards</Dropdown.Item>
                    </Dropdown.Menu>
                </div>
            </div>
        </div>

    </Popover>
));

const NavbarDown = () => {
    const navigate = useNavigate();
    const [isCollapsed, setCollapsed] = useState(true);

    const toggleNavbar = () => {
        setCollapsed(!isCollapsed);
    };

    const getSubcategories = (parentCategory, tag) => {
        const deta = Submenu.filter(
            (item) => item.ParentCategory === parentCategory && item.Tag === tag
        );
        return deta;
    };

    useEffect(() => {

        const popoverTriggerList = [].slice.call(
            document.querySelectorAll('[data-bs-toggle="popover"]')
        );

        popoverTriggerList.forEach((popoverTriggerEl) => {
            const popover = new window.bootstrap.Popover(popoverTriggerEl, {
                trigger: 'hover', // Set the trigger to 'hover'
            });
        });
    }, []);


    const cartItems = useSelector((state) => state.cart.items);

    const wishlistitems = useSelector((state) => state.wishlist.items);

    const [isCartTooltipVisible, setCartTooltipVisible] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const toggleCartTooltip = () => {
        setCartTooltipVisible(!isCartTooltipVisible);
    };

    const ref = React.useRef();
    function handleSelectMenu(eventKey, event) {
        console.log(eventKey);
        ref.current.close();
    }
    const handleSidebarToggle = (isOpen) => {
        setIsOpen(isOpen);
      };
    
      const handleButtonClick = () => {
        handleSidebarToggle(true);
      };
      const handleSidebarItemClick = (itemOptions) => {
        // Handle sidebar item click here...
      };


    return (

        <div className="container-fluid fixed-top mb-5">
            <div className='row '>
                <div className='col-md-12 p-0'>
                    <nav className="navbar navbar-expand bg-dark">
                        <div className="container-fluid">
                            <a className="navbar-brand bg-white" onClick={() => navigate("/")} href="#"><img src={logo} alt="Logo" className='img-fluid' /> </a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item sideiconsTopSingle me-2">
                                        <a className="nav-link active text-white pe-0 p-0" aria-current="page" href="#">Hello,<br /><span className='fw-bold'>Select Your Address</span></a>
                                    </li>

                                    <li className="navbar-nav nav-item mt-3" style={{ width: "50vw" }}>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text navbartopNav1" id="basic-addon1">
                                                <select className="form-select  navbartopNav form-select-sm" aria-label="Small select example">
                                                    <option selected>All Categories</option>
                                                    {Tag.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.TagName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </span>
                                            <input type="text" className="form-control " placeholder="Search Loriat" aria-label="Search Loriat" aria-describedby="basic-addon1" />
                                            <span class="input-group-text" type='submit' id="basic-addon2"><i class="fa-solid fa-magnifying-glass"></i></span>
                                        </div>
                                    </li>
                                </ul>
                                <form className="">
                                    <ul className="navbar-nav sideiconsTop me-auto">
                                        {/*<li className="nav-item">
                                            <a className="nav-link active text-white" aria-current="page" href="#">EN</a>
                                                    </li>*/}
                                        {/*<li className="nav-item">
                                            <a className="nav-link active text-white" aria-current="page" href="#">
                                                Hello, sign in Account & Lists

                                                <div className="submenu-container">

                                                    <ul>
                                                        <li><a href="#">Submenu Item 1</a></li>
                                                        <li><a href="#">Submenu Item 2</a></li>

                                                    </ul>
                                                </div>
                                            </a>
                                                    </li>*/}

                                        <li className="nav-item py-0">
                                            <Whisper
                                                placement="bottomStart"
                                                controlId="control-id-hover-enterable"
                                                trigger="hover"
                                                ref={ref}
                                                enterable
                                                speaker={<MenuPopover onSelect={handleSelectMenu} />}
                                            >
                                                <Button className='btn me-3 ' >Login</Button>
                                            </Whisper>
                                        </li>



                                        <li className="nav-item">
                                            <a title='Become a seller' className="nav-link active text-white" aria-current="page" href="#">Become a <br /> <span className='fw-bold'>Seller</span> </a>
                                        </li>
                                        <li className="nav-item">
                                            <a title='Returns and Orders' className="nav-link active text-white" aria-current="page" href="#">Returns <br /> <span className='fw-bold'>& Orders</span></a>
                                        </li>

                                        <li className="nav-item d-flex align-items-center">
                                            <div className="cart-tooltip-container">
                                                <i
                                                    onClick={() => navigate("/addtoCart")}
                                                    onMouseEnter={toggleCartTooltip}

                                                    value={cartItems.length}
                                                    className="nav-link fa-solid fa-cart-shopping text-white"
                                                ></i>
                                                {isCartTooltipVisible && <CartTooltip onMouseLeave={toggleCartTooltip} />}
                                            </div>
                                        </li>
                                    </ul>

                                </form>
                            </div>
                        </div>
                    </nav>

                </div>
                {/*<div className="col-md-12 p-0">
                    <div className="d-flex justify-content-end">
                        <form className="d-flex">
                            <div className="input-group">
                                <input className="form-control" type="search" placeholder="Search" aria-label="Search" />
                                <span className="input-group-text">
                                    <i className="fa fa-search"></i>
                                </span>
                            </div>
                        </form>
                    </div>
    </div>*/}
                <div className='col-md-12 p-0'>
                    <div className='col-md-12 p-0'>
                        <nav className="navbar navbar-expand  NavbarDown py-0">
                            <button
                                className="navbar-toggler"
                                type="button"
                                onClick={toggleNavbar}
                                aria-expanded={!isCollapsed}
                                aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`} id="navbarSupportedContent">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    {Menu.map(category => (
                                        <li className="nav-item mega-menu" key={category.CategoryName}>
                                            {category.CategoryName === "All" ?
                                                <span
                                                    className="nav-link text-white fw-bold m-1"

                                                    onClick={handleButtonClick}>
                                                    <span className="custom-text"><i class="fa-solid fa-bars me-1"/>{category.CategoryName}</span>                                              </span>
                                                :
                                                <Link
                                                    className="nav-link text-white fw-bold m-1"
                                                    to={`/${category.CategoryName}`}
                                                    onClick={toggleNavbar}>
                                                    <span className="custom-text">{category.CategoryName}</span>
                                                </Link>
                                            }
                                            <div className="mega-menu-content">
                                                <div className="mega-menu-column">
                                                    <div className='Container'>
                                                        <div className='row navlinkitems'>
                                                            {Object.values(Tag)
                                                                .filter(tagItem => tagItem.ParentCategory === category.CategoryName)
                                                                .map(tagItem => (
                                                                    <div className='col' key={tagItem.TagName}>
                                                                        <ul>
                                                                            <li>
                                                                                <Link to={``}>
                                                                                    <span className="custom-subcategory-title">{tagItem.TagName}</span>
                                                                                </Link>
                                                                            </li>
                                                                            {getSubcategories(category.CategoryName, tagItem.TagName)
                                                                                .map((item, index) => (
                                                                                    <li key={index}>
                                                                                        <Link to={`/${category.CategoryName}/${item.subCatogryName}`}>
                                                                                            <span className='itemsStyle'>{item.subCatogryName}</span>
                                                                                        </Link>
                                                                                    </li>
                                                                                ))}
                                                                        </ul>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <form className=" " role="search">
                                    <ul className="navbar-nav sideicons me-auto">
                                        <li className='nav-item' data-bs-toggle="popover" data-bs-placement="bottom" data-bs-content="User Profile">
                                            <i className='nav-link fas fa-user-alt text-white'></i>
                                        </li>

                                        <li className='nav-item'>
                                            <i class=' nav-link fas fa-heart wishlistCount text-white' value={wishlistitems.length} onClick={() => navigate("/wishlist")}></i>
                                        </li>
                                        <li className='nav-item'>

                                        </li>
                                    </ul>
                                </form>
                            </div>
                        </nav>
                    </div>

                </div>
            </div>
            <Sidebar isOpen={isOpen} onToggle={handleSidebarToggle} onItemClick={handleSidebarItemClick} />
        </div>

    );
};

export default NavbarDown;
