import React from "react";
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";

import { removeFromWishlist } from "../redux/wishlistaction"; 
import "./cartPage.css";
import { useNavigate } from "react-router-dom";

import { addToCart } from "../redux/cartActions";

import { toast } from 'react-toastify';


const Wishlistpage = () => {
    const navigate = useNavigate();
    const wishlistItems = useSelector((state) => state.wishlist.items);
    const cartItems = useSelector((state) => state.cart.items);
    const dispatch = useDispatch(); 

    const handleRemoveFromWishlist = (item) => {
        dispatch(removeFromWishlist(item));
    };
    
    const handleAddToCart = (item) => {
        const isAlreadyInCart = cartItems.some(cartItem => cartItem.id === item.id);

        if (isAlreadyInCart) {
            toast.info('Item is already in your cart', {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            dispatch(addToCart(item));
            toast.success('Item Added To Your Cart !', {
                position: toast.POSITION.TOP_RIGHT
            });
            handleRemoveFromWishlist(item);
        }
    };

    return (
        <>
        <section className="h-100 h-custom cartPage" style={{ backgroundColor: '#eee' }}>
            <Container className="py-5 h-100">
                <Row className="d-flex justify-content-center  h-100">
                    <Col>
                        <div className="card">
                            <div className="card-body p-4">
                                <Row>
                                    <Col lg={12}>
                                        <h5 className="mb-3">
                                            <a href="#!" className="text-body">
                                                <i className="fas fa-long-arrow-alt-left me-2"></i>Continue shopping
                                            </a>
                                        </h5>
                                        <hr />
                                        <div className="d-flex justify-content-between align-items-center mb-4">
                                            <div>
                                                <p className="mb-1">Wishlist</p>
                                                <p className="mb-0">You have {wishlistItems.length} items in your Wishlist</p>
                                            </div>
                                            <div>
                                                <p className="mb-0">
                                                    <span className="text-muted">Sort by:</span>{' '}
                                                    <a href="#!" className="text-body">
                                                        price <i className="fas fa-angle-down mt-1"></i>
                                                    </a>
                                                </p>
                                            </div>
                                        </div>

                                        {/* Render your card items here */}
                                        {wishlistItems.map((data) => {
                                            return (
                                                <div className="card mb-3" key={data.id}>
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="d-flex flex-row align-items-center">
                                                                <div>
                                                                    <img
                                                                        src={data.image}
                                                                        className="img-fluid rounded-3"
                                                                        alt="Shopping item"
                                                                        style={{ width: '65px' }}
                                                                    />
                                                                </div>
                                                                <div className="ms-3 w-50">
                                                                    <p>{data.name}</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-row align-items-center">
                                                                <div style={{ width: '50px' }}>
                                                                    <h5 className="fw-normal mb-0 itemnumber">{null}</h5>
                                                                </div>
                                                                <div style={{ width: '80px' }}>
                                                                    <h5 className="mb-0"><i className="fa-solid fa-indian-rupee-sign"></i> {data.mrp * data.quantity}</h5>
                                                                </div>
                                                                <button
                                                                onClick={() => handleAddToCart(data)}// Pass the product data to the function
                                                                    className="btn btn-info me-3"
                                                                >
                                                                    Add to cart
                                                                </button>
                                                                <button
                                                                    onClick={() => handleRemoveFromWishlist(data)}
                                                                    className="btn btn-info"
                                                                >
                                                                    Remove
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
    );
}

export default Wishlistpage;
