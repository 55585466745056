import React from "react";
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart, DecreaseCart } from "../redux/cartActions"; // Import the action creators
import { store } from "../redux/store";
import "./cartPage.css";

import { useNavigate } from "react-router-dom";


import { useState } from "react";











const Cart = () => {

    const navigate = useNavigate();
    const cartItems = useSelector((state) => state.cart.items);
    const dispatch = useDispatch();

    const Subtotal = cartItems.reduce((total, item) => total + item.mrp * item.quantity, 0);

    const handleAddToCart = (item) => {
        dispatch(addToCart(item));
    };

    const handleRemoveFromCart = (item) => {
        dispatch(removeFromCart(item));
    };

    const handleDecreaseCart = (item) => {
        dispatch(DecreaseCart(item));
    };






    var priceWithoutTax = (
        cartItems.reduce((total, item) => total + (parseFloat(item.quantity) * parseFloat(item.mrp)), 0).toFixed(2)
    );
    var estimatedTax = ((priceWithoutTax * 0.05).toFixed(2));
    var finalPrice = ((parseFloat(priceWithoutTax) + parseFloat(estimatedTax)).toFixed(2));







    return (
        <>
            <section className="h-custom cartPage" style={{ backgroundColor: '#eee' }}>
                <Container className="py-5 h-100 ">
                    <Row className="d-flex h-100 ">
                        <Col>
                            <div className="card">
                                <div className="card-body p-4">
                                    <Row>
                                        <Col lg={7}>
                                            <h5 className="mb-3">
                                                <a href="#!" className="text-body">
                                                    <i className="fas fa-long-arrow-alt-left me-2"></i>Continue shopping
                                                </a>
                                            </h5>
                                            <hr />
                                            <div className="d-flex justify-content-between align-items-center mb-4">
                                                <div>
                                                    <p className="mb-1">Shopping cart</p>
                                                    <p className="mb-0">You have {cartItems.length} items in your cart</p>
                                                </div>
                                                <div>
                                                    <p className="mb-0">
                                                        <span className="text-muted">Sort by:</span>{' '}
                                                        <a href="#!" className="text-body">
                                                            price <i className="fas fa-angle-down mt-1"></i>
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>

                                          
                                            {cartItems.map((data) => {


                                                return (
                                                    <div className="card mb-3" key={data.id}>
                                                        <div className="card-body">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="d-flex flex-row align-items-center">
                                                                    <div>
                                                                        <img
                                                                            src={data.image}
                                                                            className="img-fluid rounded-3"
                                                                            alt="Shopping item"
                                                                            style={{ width: '65px' }}
                                                                        />
                                                                    </div>
                                                                    <div className="ms-3 w-50">
                                                                        <p className="cartItemDesc">{data.name}</p>
                                                                        <div className="input-group flex-nowrap">
                                                                            {data.quantity >= 2 &&

                                                                                <span className="input-group-text" id="addon-wrapping" onClick={() => handleDecreaseCart(data)}>-</span>
                                                                            }
                                                                            <span className="input-group-text" >{data.quantity}</span>

                                                                            <span className="input-group-text" id="addon-wrapping" onClick={() => handleAddToCart(data)}>+</span>
                                                                            <div className="cartSavediv">
                                                                                <i class="fa fa-heart p-2" id="heart" > <span className="p-2">Save</span></i>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-row align-items-center">

                                                                    <div style={{ width: '80px' }}>
                                                                        <h5 className="mb-0"><i class="fa-solid fa-indian-rupee-sign"></i> {data.mrp * data.quantity}</h5>
                                                                    </div>



                                                                    <button
                                                                        onClick={() => handleRemoveFromCart(data)}
                                                                        className="btn btn-info d-flex align-items-center"
                                                                    >
                                                                        <i className="fa-solid fa-trash me-2"></i> <span>Remove</span>
                                                                    </button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                           
                                        </Col>
                                        <Col lg={5}>
                                            
                                            <div className="card  text-white rounded-3">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                                        <Button variant="info" onClick={() => navigate("/addresspage")}>
                                                            PROCEED TO CHECKOUT <i className="fas fa-long-arrow-alt-right ms-2"></i>
                                                        </Button>

                                                    </div>
                                                    <div className="card" >

                                                        <div className="card-body">
                                                            <div className="row p-2">
                                                                <div className="col-md-6">
                                                                    <p>Total</p>
                                                                </div>
                                                                <div className="col-md-6 text-end">
                                                                    <span><i class="fa-solid fa-indian-rupee-sign"></i>  {priceWithoutTax}</span>
                                                                </div>
                                                            </div>

                                                            <div className="row p-2">
                                                                <div className="col-md-6">
                                                                    <p>Sub Total</p>
                                                                </div>
                                                                <div className="col-md-6 text-end">
                                                                    <span className="fw-bold"><i class="fa-solid fa-indian-rupee-sign"></i>  {priceWithoutTax}</span>
                                                                </div>
                                                            </div>

                                                            <div className="row p-2">
                                                                <div className="col-md-6">
                                                                    <p>Estimated Tax</p>
                                                                </div>
                                                                <div className="col-md-6 text-end">
                                                                    <span className=""><i class="fa-solid fa-indian-rupee-sign"></i>  {estimatedTax}</span>
                                                                </div>
                                                            </div>

                                                            <div className="row p-2">
                                                                <div className="col-md-6">
                                                                    <p>You Pay</p>
                                                                </div>
                                                                <div className="col-md-6 text-end">
                                                                    <span className="fw-bold"><i class="fa-solid fa-indian-rupee-sign"></i>  {finalPrice}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Form className="mt-4">
                                                        <Button variant="info"> PROCEED TO CHECKOUT <i className="fas fa-long-arrow-alt-right ms-2"></i> </Button>
                                                    </Form>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    )
}



export default Cart;