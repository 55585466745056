import React, { useEffect } from "react";
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';


const PrivateRoute = ({ isAdmin, children }) => {
  // const navigate = useNavigate();

//   const { loading, user, isAuthenticated } = useSelector(
//     (state) => state.user
//   );
 

//   if (loading ) {
//     return <Loader />;
//   }

const  isAuthenticated= true

  if (!isAuthenticated) {
    return < Navigate to="/LoginSignUp" />
  };
 
  if (isAdmin === true ) {
    return < Navigate to="/LoginSignUp" />
  };
  return children ? children : <Outlet />;
};



export default PrivateRoute;

