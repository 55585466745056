import React from "react";
import  "./EcommerceBanner.css";

const EcommerceBanner = ({ data }) => {
  return (
    <div className="ecommerce-banner">
     <div className="banner-img">
     <img src={data.imageBanner} alt={data.name} className="img-fluid" />
     <img src={data.imageBannerSecond} alt={data.name} className="img-fluid" />
     </div>
    </div>
  );
};

export default EcommerceBanner;
