import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from "react-redux";
import {  removeFromCart } from "../redux/cartActions";

const CartTooltip = ({ onMouseLeave }) => {
    
    const cartItems = useSelector((state) => state.cart.items);
    const Navigate = useNavigate();
  
    const dispatch = useDispatch();

    const handleRemoveFromCart = (item) => {
        dispatch(removeFromCart(item)); // Use the removeFromCart action creator
    };




    return (
        <div className= {cartItems.length ===0 ?"cart-tooltip p-4 tollpit-hight-remove ":"cart-tooltip p-3 "} onMouseLeave={onMouseLeave}>
        {cartItems.length ===0 ?
            <>
            <p className='text-primary text-center mb-3  border-bottom border-top-0'> your shopping bag is empty.</p>
            <button   className='btn btn-dark d-block float-end mb-2 mt-2 '>Continue Shopping</button> 
            </>
            :
            cartItems.map((data) => {
                 

                return (

                    <>

                        <div class="card mb-3 p-2" style={{ maxWidth: " 540px" }} key={data.id}>
                        
                            <div class="row g-0">
                                <div class="col-md-2 " style={{ height: "6rem" }}>
                                    <img src={data.image} class="img-fluid rounded-start w-100 h-100" alt="..." />
                                </div>
                                <div class="col-md-10">

                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className="cartItemDesc px-2 m-auto">{data.name}
                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                        <div class="card-body">
                                        <div className='row justify-content-between'>
                                                <div class="col-4">
                                                    <div className='carttoolsize'>
                                                        SIZE M
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div className='cartQuantiytip'>
                                                        Quantity {data.quantity}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row justify-content-between'>
                                                <div class="col-4">
                                                    <div className='wishlistPrice'>
                                                        <i className="fa fa-indian-rupee-sign"></i>{' '}
                                                        {data.mrp * data.quantity}
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div className='editdelicon'>
                                                        <i class="fa-solid fa-pencil "  onClick={() => Navigate("/addtoCart")}></i>
                                                        <i class="fa-solid fa-trash " onClick={() => handleRemoveFromCart(data)}></i>
                                                    </div>
                                                </div>
                                            </div>

                                    </div>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>






                    
                    </>
                );
            })
        }
        </div>
    );
};

export default CartTooltip;
