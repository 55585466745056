import React from "react";
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from "react";
import Data from "../data/data.json"
import { addToCart } from "../redux/cartActions";
import { useNavigate } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AddressPage.css'



const ProductDetails = () => {
   
  
    const dispatch = useDispatch();

    const handleAddToCart = () => {
       
        dispatch(addToCart(product));
        toast.success('Item Added To Your Cart !', {
            position: toast.POSITION.TOP_RIGHT
        });
      };

      

    

    const { id } = useParams();
    const product = Data.find(item => item.id === parseInt(id));
  

  
        const [quantity, setQuantity] = useState(1);
      
        const handleDecrease = () => {
          if (quantity > 1) {
            setQuantity(quantity - 1);
          }
        };
      
        const handleIncrease = () => {
          setQuantity(quantity + 1);
        };

    return (
        <div className="container  mt-5 ">
        <ToastContainer />

            <div className="row">

                <div className="col-md-12">

                    <section className="py-5">
                        <div className="container productDetails">
                            <div className="row gx-5">
                                <aside className="col-lg-6">
                                    <div className=" rounded-4 mb-3 d-flex justify-content-center">
                                        <a
                                            data-fslightbox="mygalley"
                                            className="rounded-4"
                                            target="_blank"
                                            data-type="image"
                                            href={product.image}
                                        >
                                            <img
                                                className="rounded-4 img-fluid"
                                                src={product.image}
                                                alt="Product"
                                            />
                                        </a>
                                    </div>
                                    <div className="d-flex justify-content-center mb-3">
                                        <a
                                            data-fslightbox="mygalley"
                                            className="border mx-1 rounded-2"
                                            target="_blank"
                                            data-type="image"
                                            href="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/detail1/big1.webp"
                                        >
                                            <img
                                                width="60"
                                                height="60"
                                                className="rounded-2"
                                                src={product.image}
                                                alt="Thumbnail"
                                            />
                                        </a>

                                    </div>
                                </aside>
                                <main className="col-lg-6">
                                    <div className="ps-lg-3">
                                        <h4 className="title text-dark">
                                            {product.desecriptions?.Description}
                                        </h4>

                                        <div className="d-flex flex-row my-3">

                                            <div className="text-warning mb-1 me-2">
                                                <i className="fa fa-star"></i>

                                                <span className="ms-1">{product.Rating}</span>
                                            </div>

                                            <span className="text-muted">
                                                <i className="fas fa-shopping-basket fa-sm mx-1"></i>154
                                                orders
                                            </span>
                                            <span className="text-success ms-2">In stock</span>
                                        </div>

                                        <div className="mb-3">
                                            <span className="h5">Rs{product.mrp}</span>
                                            <span className="text-muted">/per box</span>
                                        </div>

                                        <p>
                                            Modern look and quality demo item is a streetwear-inspired
                                            collection that continues to break away from the conventions of
                                            mainstream fashion. Made in Italy, these black and brown
                                            clothing low-top shirts for men.
                                        </p>

                                        <div className="row">
                                            <dt className="col-3">Type:</dt>
                                            <dd className="col-9">Regular</dd>

                                        </div>
                                        <hr />

                                        <div className="row mb-4">
                                            <div className="col-md-4 col-6">
                                                <label className="mb-2">Size</label>
                                                <select
                                                    className="form-select border border-secondary"
                                                    style={{ height: '35px' }}
                                                    value={null}
                                                >
                                                    {product.size.map((size, index) => (
                                                        <option key={index}>{size}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-4 col-6 mb-3">
                                                <label className="mb-2 d-block">Quantity</label>
                                                <div className="input-group mb-3" style={{ width: '170px' }}>
                                                    <button
                                                        className="btn btn-white border border-secondary px-3"
                                                        type="button"
                                                        id="button-addon1"
                                                        data-mdb-ripple-color="dark"
                                                        onClick={handleDecrease}
                                                    >
                                                        <i className="fas fa-minus" aria-hidden="true"></i>
                                                    </button>
                                                    <input
                                                        type="text"
                                                        className="form-control text-center border border-secondary"
                                                        placeholder="14"
                                                        aria-label="Example text with button addon"
                                                        aria-describedby="button-addon1"
                                                        value={quantity}
                                                        readOnly
                                                    />
                                                    <button
                                                        className="btn btn-white border border-secondary px-3"
                                                        type="button"
                                                        id="button-addon2"
                                                        data-mdb-ripple-color="dark"
                                                        onClick={handleIncrease}
                                                    >
                                                        <i className="fas fa-plus" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <a href="#" className="btn btn-warning shadow-0">
                                            Buy now
                                        </a>
                                        <Link to={""} onClick={handleAddToCart} className="btn btn-primary shadow-0">
                                            <i className="me-1 fa fa-shopping-basket"></i> Add to cart
                                        </Link>
                                        <a
                                            href="#"
                                            className="btn btn-light border border-secondary py-2 icon-hover px-3"
                                        >
                                            <i className="me-1 fa fa-heart fa-lg" ></i> Save
                                        </a>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </section>

                    <section className="bg-light border-top py-4">
                    <div className="container">
                      <div className="row gx-4">
                        <div className="col-lg-8 mb-4">
                          <div className="border rounded-2 px-3 py-2 bg-white">
                           
                          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                          <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Description</button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Product</button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Description</button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-disabled-tab" data-bs-toggle="pill" data-bs-target="#pills-disabled" type="button" role="tab" aria-controls="pills-disabled" aria-selected="false" disabled>Description</button>
                          </li>
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                          <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                            Product Code: {product.desecriptions.productCode}</div>
                          <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">...</div>
                          <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">...</div>
                          <div class="tab-pane fade" id="pills-disabled" role="tabpanel" aria-labelledby="pills-disabled-tab" tabindex="0">...</div>
                        </div>
                           
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="px-0 border rounded-2 shadow-0">
                            <div className="card">
                              <div className="card-body">
                                <h5 className="card-title">Similar items</h5>
                                <div className="d-flex mb-3">
                                  <a href="#" className="me-3">
                                    <img src={product.image} style={{ minWidth: '96px', height: '96px' }} className="img-md img-thumbnail" alt="Product" />
                                  </a>
                                  <div className="info">
                                    <a href="#" className="nav-link mb-1">
                                      Rucksack Backpack Large <br />
                                      Line Mounts
                                    </a>
                                    <strong className="text-dark"> {product.mrp}</strong>
                                  </div>
                                </div>
                                {/* Add similar d-flex sections for other similar items */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                </div>


            </div>
        </div>
    )
}


export default ProductDetails;