import React from "react";
import "./AddressPage.css";

import googleIcon from "../images/googleLogin.png"

import { useDispatch, useSelector } from "react-redux";



const AddressPage = () => {
    const cartItems = useSelector((state) => state.cart.items);
    const dispatch = useDispatch();
    var priceWithoutTax = (
        cartItems.reduce((total, item) => total + (parseFloat(item.quantity) * parseFloat(item.mrp)), 0).toFixed(2)
    );
    var estimatedTax = ((priceWithoutTax * 0.05).toFixed(2));
    var finalPrice = ((parseFloat(priceWithoutTax) + parseFloat(estimatedTax)).toFixed(2));

    console.log(finalPrice)
    return (
        <div className="container" style={{ marginTop: '10%' }}>
            <div className="row">

                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">




                            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h1 className="modal-title fs-5" id="exampleModalLabel">Login/Signup</h1>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="mb-3">
                                                <label for="exampleFormControlInput1" class="form-label">Login/Signup</label>
                                                <p>We promise to keep your details safe with us.</p>
                                                <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Email/Phone" />
                                                <button className="w-100 mt-4 btn  btn-info">Proceed</button><br /> <span className="text-center mt-4">OR</span><br />
                                                <button className="btn btn-info w-100 mt-4"><img src={googleIcon} class="w-25 img-fluid" alt="..." />Signed in</button>

                                                <p className="mt-4"> <span className="text-primary ">By continuing, I agree to the</span> Terms of Use & Privacy Policy</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-7">
                    <div class="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Login/Register
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <button type="button btn-success" className="btn btn-info" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        Login/Register
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Shipping Address
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12 mb-4">
                                                <div className="card mb-4">
                                                    <div className="card-header py-3">
                                                        <h5 className="mb-0">Shipping Address</h5>
                                                    </div>
                                                    <div className="card-body">
                                                        <form>
                                                            {/* 2 column grid layout with text inputs for the first and last names */}
                                                            <div className="row mb-4">
                                                                <div className="col">
                                                                    <div className="form-outline">
                                                                        <input type="text" id="form7Example1" className="form-control" />
                                                                        <label className="form-label" htmlFor="form7Example1">First name</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="form-outline">
                                                                        <input type="text" id="form7Example2" className="form-control" />
                                                                        <label className="form-label" htmlFor="form7Example2">Last name</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* Text input */}
                                                            <div className="form-outline mb-4">
                                                                <input type="text" id="form7Example3" className="form-control" />
                                                                <label className="form-label" htmlFor="form7Example3">Company name</label>
                                                            </div>

                                                            {/* Text input */}
                                                            <div className="form-outline mb-4">
                                                                <input type="text" id="form7Example4" className="form-control" />
                                                                <label className="form-label" htmlFor="form7Example4">Address</label>
                                                            </div>

                                                            {/* Email input */}
                                                            <div className="form-outline mb-4">
                                                                <input type="email" id="form7Example5" className="form-control" />
                                                                <label className="form-label" htmlFor="form7Example5">Email</label>
                                                            </div>

                                                            {/* Number input */}
                                                            <div className="form-outline mb-4">
                                                                <input type="number" id="form7Example6" className="form-control" />
                                                                <label className="form-label" htmlFor="form7Example6">Phone</label>
                                                            </div>

                                                            {/* Message input */}
                                                            <div className="form-outline mb-4">
                                                                <textarea className="form-control" id="form7Example7" rows="4"></textarea>
                                                                <label className="form-label" htmlFor="form7Example7">Additional information</label>
                                                            </div>

                                                            {/* Checkbox */}
                                                            <div className="form-check d-flex justify-content-center mb-2">
                                                                <input className="form-check-input me-2" type="checkbox" value="" id="form7Example8" checked />
                                                                <label className="form-check-label" htmlFor="form7Example8">
                                                                    Create an account?
                                                                </label>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Payment
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <section className="gradient-custom">
                                        <div className="container ">
                                            <div className="row d-flex justify-content-center py-5">
                                                <div className="col-md-12">
                                                    <div className="card" >
                                                        <div className="card-body p-4">
                                                            <form>
                                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                                    <div className="form-outline">
                                                                        <input type="text" id="typeText" className="form-control form-control-lg" siez="17"
                                                                            placeholder="1234 5678 9012 3457" minlength="19" maxlength="19" />
                                                                        <label className="form-label" for="typeText">Card Number</label>
                                                                    </div>
                                                                    <img src="https://img.icons8.com/color/48/000000/visa.png" alt="visa" width="64px" />
                                                                </div>

                                                                <div className="d-flex justify-content-between align-items-center mb-4">
                                                                    <div className="form-outline">
                                                                        <input type="text" id="typeName" className="form-control form-control-lg" siez="17"
                                                                            placeholder="Cardholder's Name" />
                                                                        <label class="form-label" for="typeName">Cardholder's Name</label>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex justify-content-between align-items-center pb-2">
                                                                    <div className="form-outline">
                                                                        <input type="text" id="typeExp" className="form-control form-control-lg" placeholder="MM/YYYY"
                                                                            size="7" id="exp" minlength="7" maxlength="7" />
                                                                        <label className="form-label" for="typeExp">Expiration</label>
                                                                    </div>
                                                                    <div className="form-outline">
                                                                        <input type="password" id="typeText2" className="form-control form-control-lg"
                                                                            placeholder="&#9679;&#9679;&#9679;" size="1" minlength="3" maxlength="3" />
                                                                        <label className="form-label" for="typeText2">Cvv</label>
                                                                    </div>
                                                                    <button type="button" className="btn btn-info btn-lg btn-rounded">
                                                                        <i className="fas fa-arrow-right"></i>
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-5">
                    <div className="orderSummary">
                        <div className="card" >
                            <div className="card-body">
                                <h5 className="card-title text-center">ORDER SUMMARY</h5>

                                <div className="row border-top">
                                    <div className="col-md-6">
                                        <p>Total</p>
                                    </div>
                                    <div className="col-md-6 text-end">
                                        <span><i class="fa-solid fa-indian-rupee-sign"></i>  {priceWithoutTax}</span>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <p>Sub Total</p>
                                    </div>
                                    <div className="col-md-6 text-end">
                                        <span className="fw-bold"><i class="fa-solid fa-indian-rupee-sign"></i>  {priceWithoutTax}</span>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <p>Estimated Tax</p>
                                    </div>
                                    <div className="col-md-6 text-end">
                                        <span className=""><i class="fa-solid fa-indian-rupee-sign"></i>  {estimatedTax}</span>
                                    </div>
                                </div>

                                <div className="row  border-top border-3">
                                    <div className="col-md-6">
                                        <p>You Pay</p>
                                    </div>
                                    <div className="col-md-6 text-end">
                                        <span className="fw-bold"><i class="fa-solid fa-indian-rupee-sign"></i>  {finalPrice}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default AddressPage;