import React from "react";
import MultilevelSidebar from "react-multilevel-sidebar";
import "react-multilevel-sidebar/src/Sidebar.css";
import datamenu from "../data/dataMenu.json";
import dataSubcat from "../data/dataSubcat.json";
import "../../src/customSidebar.css"

function generateCategoryOptions() {
    return datamenu.map(category => {
      const subcategories = dataSubcat
        .filter(subcategory => subcategory.ParentCategory === category.CategoryName)
        .map(subcategory => subcategory.subCatogryName);
        
        console.log('Category:', category.CategoryName);
        console.log('Subcategories:', subcategories);
  
      return {
        title: category.CategoryName,
        titleIcon: <i className="fa fa-graduation-cap"/>,
        content: [
          ...subcategories.map(subcategory => ({
            id: category.id,
            name: subcategory,
            to: `/category/${category.id}/${subcategory}`,
          })),
        ],
      };
    });
  }

  const options = [
    {
      title: "Trending",
      titleIcon: <i className="fa-solid fa-arrow-trend-up" />,
      content: [
        { id: 1, name: "Best Sellers", to: "/" },
        { id: 2, name: "New Releases", to: "/" },
        { id: 3, name: "Movers and Shakers", to: "/" },
      ],
    },
    {
      title: "Shop By category",
      titleIcon: <i className="fa-solid fa-basket-shopping" />,
      hideBorder: true,
      content: [
        ...datamenu.map(category => ({
          id: category.id,
          name: category.CategoryName,
          children: generateCategoryOptions(),
        })),
      ],
    },
  ];


const header = 
    {
        id : 1,
        title: "Hello, sign in",
        titleIcon: <i className="fa-solid fa-circle-user"/>,
    

}



function Sidebar({ isOpen, onToggle, onItemClick }) {
  
  const handleClick = (itemOptions) => {
   
      console.log(itemOptions);
       
    };

    return (
        <MultilevelSidebar
            open={isOpen}
            onToggle={onToggle}
            options={options}
            header={
                <div className="header-container">
                   
                    <div className="sidebar-header ">{header.titleIcon}{header.title}</div>
                </div>
            }
            onItemClick={()=>handleClick(options)}
            persist={false}
        />
    );
}

export default Sidebar;
