import React from 'react';

const ProductCard = ({ productName }) => {
  return (
    <div className="product-card">
      <img src={`path_to_images/${productName}.jpg`} alt={productName} />
      <h3>{productName}</h3>
      <p>Product description here...</p>
    </div>
  );
};

export default ProductCard;
