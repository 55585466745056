
import './App.css';
import AllRoute from './Routes/AllRoute';
import Navbar from './Components/layout/navbar';

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <>
    <div className='App'>
    <ToastContainer 
    autoClose={1000}
    theme="dark"
    />
    <Navbar/>
    <AllRoute/>
    </div>
   
    </>
  );
}

export default App;
