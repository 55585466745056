import React from "react";
import { useParams } from "react-router-dom";
import EcommerceCard from "./EcommerceCard";
import EcommerceBanner from "./EcommerceBanner";
import Data from './data/data.json';
import SubCatogry from './data/dataSubcat.json'


const SubcategoryPage = () => {
 
  const { category, subcategory } = useParams();

  const filterData = Object.values(Data).filter(
    (categoryData) =>
      categoryData.catogry === category && categoryData.subCatogry === subcategory
  );
  const filterbanner = Object.values(SubCatogry).filter(
    (categoryData) =>
      categoryData.ParentCategory === category && categoryData.subCatogryName === subcategory
  );

  return (
    <div className="subcategory-page" style={{marginTop: "10%"}}>
      {filterbanner && <EcommerceBanner data={filterbanner[0]} />}

      <div className="row p-0">
        {filterData.map((data) => (
          <div className="col-md-3 col-sm-6 col-lg-3 p-0" key={data.id}>
            <EcommerceCard data={data} isUserLoggedIn={true} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubcategoryPage;
