import React, { useState } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import Home from "../Components/Home";
import SubcategoryPage from "../SubcategoryPage";
import ProductDetails from "../pages/productDetails";
import Cart from "../pages/cart";
import AddressPage from "../pages/AddressPage";
import LoginSignupPopup from "../pages/LoginSignUp";
import Protected from './ProtectedRoute'

import Wishlistpage from "../pages/wishlistpage";

const AllRoute = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate(); // Get the navigate function

  const handleUserIconClick = () => {
    setIsLoggedIn(true);
  };

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:category/:subcategory" element={<SubcategoryPage />} />
      <Route path="/productDetails/:id" element={<ProductDetails />} />
      <Route path="/addtoCart" element={<Cart />} />
      

      {/* Regular route */}
      <Route path="/loginSignupPopup" element={<LoginSignupPopup />} />

      {/* Protected route */}
      <Route element={<Protected />}>
        <Route path="/addressPage" element={< AddressPage />} />
        <Route path="/wishlist" element={< Wishlistpage />} />

      </Route>

    </Routes>
  );
};

export default AllRoute;
