import React from "react";



const LoginSignUp = () => {
    return(
        <div className="container">
        <h1>This is LoginSIgnpage</h1>
        </div>
    )
}

export default LoginSignUp;