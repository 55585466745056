import React, { useState, useEffect } from "react";
import "./EcommerceCard.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../src/redux/cartActions";
import { addToWishlist, removeFromWishlist } from "../src/redux/wishlistaction";
import { toast } from 'react-toastify';
import HeartAnimation from "./HeartAnimation";
import { Modal } from 'rsuite';
import googleIcon from "../src/images/googleLogin.png"
import alternateImage from "../src/images/alternateimage.avif"
import star from "../src/images/staricon.png"



const EcommerceCard = ({ data, isUserLoggedIn }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddToCart = (item) => {
    dispatch(addToCart(item));
    toast.success("Item Added To Your Cart !", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const wishlistItems = useSelector(state => state.wishlist.items);
  const isHeartRed = wishlistItems.some(item => item.id === data.id);

  const handleHeartClick = () => {
    if (isUserLoggedIn) {
      if (isHeartRed) {
        dispatch(removeFromWishlist(data));
        toast.success("Item removed from Your Wishlist !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        dispatch(addToWishlist(data));
        toast.success("Item Added To Your Wishlist !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      setOpen(true);
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [isHovered, setIsHovered] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);

  useEffect(() => {
    return () => {
      clearTimeout(hoverTimeout);
    };
  }, [hoverTimeout]);

  const handleMouseEnter = () => {
    clearTimeout(hoverTimeout);
    setHoverTimeout(
      setTimeout(() => {
        setIsHovered(true);
      }, 100)
    );
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
    setHoverTimeout(
      setTimeout(() => {
        setIsHovered(false);
      }, 100)
    );
  };

  const ratings = data.Review.map(review => review.rating);
  console.log(ratings);



  return (
    <div className="ecommerce-card m-2 mt-3" onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <img
        src={isHovered ? alternateImage : data.image}
        alt={data.name}
        className="card-img-top"
        onClick={() => navigate(`/productDetails/${data.id}`)}
      />
      <div className="card-body">
        <p className="card-title product-description">
          {data.desecriptions?.Description}
        </p>
        <p className="card-text product-price"><i class="fa-solid fa-indian-rupee-sign fw-bold"></i>{data.mrp}</p>

        <div className="container">
          <div className="row">
            <div className="col-md-12 text-end ">



              <HeartAnimation isHeartRed={isHeartRed} onClick={handleHeartClick} />

              <Modal open={open} onClose={handleClose}>
                <Modal.Header>
                  <Modal.Title> <span className="fw-bold">Login / Sign Up</span> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div class="mb-3 text-center">

                    <p>We promise to keep your details safe with us.</p>
                    <input type="text" class="form-control mt-3" id="exampleFormControlInput1" placeholder="Email/Phone" />
                    <button className="w-100 mt-4 btn  btn-info">Proceed</button><br /> <span className="text-center pt-5">OR</span><br />
                    <button className="btn btn-info w-100 mt-4"><img src={googleIcon} class=" img-fluid me-2" alt="..." style={{ width: "18px" }} />Signed in</button>

                    <p className="mt-4"> <span className="text-primary ">By continuing, I agree to the</span> Terms of Use & Privacy Policy</p>
                  </div>

                </Modal.Body>

              </Modal>
            </div >
            <div className="col-md-3 text-left">
             <span className="d-flex"> <img src={star} className="img-fluid"/><span className="ratingNum pt-2">{ratings}</span> </span>
            </div>
            <div className="col-md-9 card-buttons text-end">
              <div className="cardButtnecomm">
                <button
                  className="btn btn-primary AddtoCartBtn  "
                  onClick={() => handleAddToCart(data)}
                >
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default EcommerceCard;
